import React from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import { TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/llave.png'
import { ContractButtonSmall } from '../components/Solutions/SolutionDetail.styled'
import FooterContact from './../components/FooterContact'

const Llave = props => (
  <Layout
    title="Servicio Llave en mano"
    description="Con nuestro servicio llave en mano tu empresa quedará legalmente constituida en México y bajo todos los requisitos tanto legales como fiscales en menos de 30 días. Al finalizar ese tiempo podrás facturar, importar y/o exportar.

    "
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.llave.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedMessage id="solutions.llave.content.p1" />
        </p>
        <h2>
          <FormattedMessage id="solutions.llave.content.subtitle" />
        </h2>
        <ul>
          {_.map(_.times(12, String), (value, key) => (
            <li key={key}>
              <FormattedHTMLMessage
                id={`solutions.llave.content.list.item${value}`}
              />
            </li>
          ))}
        </ul>
        <p>
          <b>
            <FormattedHTMLMessage id="solutions.llave.content.label_link" />
          </b>
          <Link to="/constituir-empresa">
            <FormattedHTMLMessage id="solutions.llave.content.link" />
          </Link>
        </p>
        <p style={{ textAlign: 'center' }}>
          <FormattedMessage id="solutions.llave.content.cost" />
        </p>
        <p>
          <FormattedMessage id="solutions.llave.content.terms" />
        </p>
      </div>
      <ContractButtonSmall to="/contacto/" small>
        <FormattedMessage id="element.button_hire" />
      </ContractButtonSmall>
    </Section>

    <FooterContact />
  </Layout>
)

export default Llave
